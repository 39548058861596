import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import {
  Box,
  Button,
  Flex,
  Heading,
  UnorderedList,
  ListItem,
  Text,
} from "@chakra-ui/react"
import HeroLeft from "../../components/hero-left"
import HeroRight from "../../components/hero-right"

const styles = {
  root: {
    width: "100%",
    height: "auto",
    px: "24px",
    bg: "#F4F4F4",
  },

  "heading-box": {
    margin: "0 auto",
    mb: "32px",
    width: { base: "100%", md: "100%", lg: "50%" },
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "24px", md: "24px", lg: "48px" },
    mt: { base: "24px", md: "24px", lg: "32px" },
  },

  hero: {
    "inner-box": {
      width: { base: "100%", md: "100%", lg: "1112px" },
      justifyContent: {
        base: "flex-start",
        md: "flex-start",
        lg: "space-between",
      },
      alignItems: { base: "center", md: "center", lg: "flex-start" },
      flexDirection: { base: "column", md: "column", lg: "row" },
    },
  },

  "title-box": {
    width: "100%",
    mb: { base: "24px", md: "24px", lg: "32px" },
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  "content-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
    flexDirection: "column",
    ml: { base: "0px", md: "0px", lg: "140px" },
  },

  "content-box-border": {
    flexDirection: "column",
    justifyContent: { base: "center", md: "center", lg: "flex-start" },
    border: { base: "1px solid #DCE0E5", md: "1px solid #DCE0E5", lg: "none" },
    borderRadius: { base: "8px", md: "8px", lg: "none" },
    padding: { base: "35px", md: "35px", lg: "0px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "429px" },
  },

  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  uo: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left",
  },

  whitespace: {
    pb: "24px",
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToLearnMoreAboutCodingOptimization = () =>
  navigate("/learn-more-about-surgical-coding-optimization/")

const SectionMiscodedCases = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}>
          Traditional Coding Solutions Miss 10% of Miscoded Surgical Cases
        </Heading>
      </Flex>
      <HeroLeft
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/coding-optimization/co-surgical-cases-a.png"
            alt="Illustration of a person looking out of a telescope with gears and puzzle piece background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>Sometimes even more…</Heading>
          </Flex>

          <Text sx={styles.text}>
            Coding guidelines and review processes are designed to prevent and
            identify the most common errors. Complex and nuanced surgical cases
            are often miscoded, even when examined by your code review team or a
            consulting service.
            <Text sx={styles.whitespace}></Text>
            Avant-garde supplements your current coding reviews with a unique
            approach that pinpoints errors missed by traditional tools,
            practices and services.
          </Text>
        </Flex>
      </HeroLeft>

      <HeroRight
        styles={styles.hero}
        Image={() => (
          <StaticImage
            src="../../images/coding-optimization/co-surgical-cases-b.png"
            alt="Illustration of a person looking out of a telescope with gears and puzzle piece background"
            placeholder="blurred"
          />
        )}
      >
        <Flex sx={styles["content-box-border"]}>
          <Flex sx={styles["title-box"]}>
            <Heading sx={styles.title}>
              What are your greatest challenges to accurate coding?
            </Heading>
          </Flex>
          <UnorderedList listStylePosition="inside">
            <ListItem>Long, confusing notes</ListItem>
            <ListItem>Lack of physician training</ListItem>
            <ListItem>Physician apathy</ListItem>
            <ListItem>Staff turnover and inconsistent retraining</ListItem>
            <ListItem>Organizational friction</ListItem>
            <ListItem>Chronic under-coding</ListItem>
          </UnorderedList>
          <Text sx={styles.whitespace}></Text>
          <Text sx={styles.text}>
            The unique Avant-garde platform obviates ALL of these challenges to
            expose new revenue opportunities.
          </Text>
        </Flex>
      </HeroRight>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "201px",
          margin: "0 auto",
          pb: "32px",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToLearnMoreAboutCodingOptimization}
        >
          Take a closer look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionMiscodedCases
