import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Box, Button, Flex, Heading, Link, Text } from "@chakra-ui/react"

const styles = {
  root: {
    height: "auto",
    width: "100%",
    mt: "64px",
    mb: { base: "0px", md: "0px", lg: "100px" },
    px: "24px",
  },

  "inner-box": {
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    justifyContent: {
      base: "flex-start",
      md: "flex-start",
      lg: "space-between",
    },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    width: { base: "100%", md: "100%", lg: "1112px" },
  },

  "left-box": {
    flexDirection: "column",
    justifyContent: "center",
    my: { base: "24px", md: "24px", lg: "0px" },
  },

  hero: {
    width: { base: "auto", md: "auto", lg: "429px" },
    height: "auto",
  },

  "heading-box": {
    margin: "0 auto",
    justifyContent: "center",
    my: { base: "24px", md: "24px", lg: "32px" },
    width: "100%",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: "24px",
    lineHeight: "32px",
    display: { base: "block", md: "block", lg: "none" },
  },

  "desktop-heading": {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: "32px",
    lineHeight: "40px",
    mb: "24px",
    display: { base: "none", md: "none", lg: "block" },
  },

  "content-box": {
    width: { base: "100%", md: "100%", lg: "500px" },
    flexDirection: "column",
  },
  text: {
    color: "#041424",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  whitespace: {
    pb: "24px",
  },

  "button-box": {
    justifyContent: {
      base: "center",
      md: "center",
      lg: "flex-start",
    },
  },

  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "143px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")
const navigateToLearnMoreAboutCodingOptimization = () =>
  navigate("/learn-more-about-surgical-coding-optimization/")

const SectionCloserLook = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["heading-box"]}>
        <Heading sx={styles.heading}> Ready to Take a Closer Look?</Heading>
      </Flex>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mb: { base: "24px", md: "24px", lg: "44px" },
        }}
      >
        <Flex sx={styles["inner-box"]}>
          <Flex sx={styles["left-box"]}>
            <Heading sx={styles["desktop-heading"]}>
              Ready to Take a Closer Look?
            </Heading>
            <Text sx={styles.text}>
              New opportunities for revenue capture are within easy reach.
              <Text sx={styles.whitespace}></Text>
              Contact us today for a rapid assessment of your potential gains!
            </Text>
            <Flex sx={styles["button-box"]}>
              <Button sx={styles.button} onClick={navigateToContactUs}>
                Contact Us
              </Button>
            </Flex>
          </Flex>

          <Box sx={styles.hero}>
            <Link onClick={navigateToLearnMoreAboutCodingOptimization}>
              <StaticImage
                src="../../images/coding-optimization/co-closer-look.png"
                alt="Click here to request a demonstration of our medical coding optimization platform in action."
                placeholder="blurred"
              />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
export default SectionCloserLook
